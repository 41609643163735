import React from "react";
import Helmet from "react-helmet";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";

import { buildMeta } from "./utils";

const SEO_QUERY = graphql`
  query SEOQuery {
    site {
      siteMetadata {
        title
        author
        keywords
      }
    }
  }
`;

const SEO = ({ meta, title, keywords, description }) => (
  <StaticQuery
    query={SEO_QUERY}
    render={({ site }) => {
      const fullTitle = `${title} | ${site.siteMetadata.title}`;
      return (
        <Helmet
          htmlAttributes={{ lang: "en" }}
          title={fullTitle}
          meta={buildMeta({
            meta,
            title: fullTitle,
            keywords: [...keywords, ...site.siteMetadata.keywords],
            description,
            author: site.siteMetadata.author,
          })}
        />
      );
    }}
  />
);

SEO.defaultProps = {
  meta: [],
};

SEO.propTypes = {
  meta: PropTypes.array,
  title: PropTypes.string.isRequired,
  keywords: PropTypes.arrayOf(PropTypes.string).isRequired,
  description: PropTypes.string.isRequired,
};

export default SEO;
