export const buildMeta = ({ meta, title, keywords, description, author }) => [
  {
    name: `description`,
    content: description,
  },
  {
    property: `og:title`,
    content: title,
  },
  {
    property: `og:description`,
    content: description,
  },
  {
    property: `og:type`,
    content: `website`,
  },
  {
    name: `twitter:card`,
    content: `summary`,
  },
  {
    name: `twitter:creator`,
    content: author,
  },
  {
    name: `twitter:title`,
    content: title,
  },
  {
    name: `twitter:description`,
    content: description,
  },
  {
    // Google verification
    name: "google-site-verification",
    content: "u7XH_5-b8ijHtb3qN2DbbEE50JRrhb0WHUsyI0eNi9Y",
  },
  {
    name: "keywords",
    content: keywords.join(`, `),
  },
  ...meta,
];
