import React, { Fragment } from "react";

import SEO from "components/utils/SEO";
import StarsView from "components/views/Stars";

const IndexPage = () => (
  <Fragment>
    <SEO
      title="Home"
      keywords={["canvas stars", "landing page", "awesome design", "responsive portfolio"]}
      description="I am Edvards Lazdans. Full-stack web developer."
    />

    <StarsView />
  </Fragment>
);

export default IndexPage;
