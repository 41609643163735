import React, { Component, Fragment } from "react";

import Text from "components/atoms/Text";
import SSRAnimation from "components/atoms/SSRAnimation";
import { Stars } from "utils/stars";

import { Canvas, Title, Wrapper } from "./styles";

class StarsView extends Component {
  componentDidMount = () => (this.stars = new Stars(document.querySelector("#stars")));
  componentWillUnmount = () => this.stars.remove();

  render() {
    return (
      <Fragment>
        <Wrapper>
          <SSRAnimation>
            <Canvas id="stars" />
          </SSRAnimation>
        </Wrapper>

        <Title>
          <Text align="center" color="light" variant="h1">
            <Text inline>Hello, I am&nbsp;</Text>
            <Text inline color="secondary">
              Edvards Lazdans
            </Text>
            <Text>Full-stack web developer.</Text>
          </Text>
        </Title>
      </Fragment>
    );
  }
}

export default StarsView;
