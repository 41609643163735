import styled from "styled-components";

import variables from "styles/variables";

export const Wrapper = styled.div`
  width: 100%;
  height: calc(100% - ${variables.fullHeader}px);
  left: 0;
  bottom: 0;
  position: absolute;
  overflow: hidden;
  background-color: ${variables.colors.space.out};
`;

export const Canvas = styled.canvas`
  width: 100%;
  height: 100%;
  display: block;
  pointer-events: none;
`;

export const Title = styled.div`
  top: 50%;
  left: 50%;
  position: relative;
  transform: translate(-50%, -50%);
  width: 100%;
`;
