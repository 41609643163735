import variables from "styles/variables";

const STAR = {
  CUR_X: 0,
  CUR_Y: 1,
  CUR_Z: 2,
  SPEED: 3,
  ORI_X: 4,
  ORI_Y: 5,
};

const DEFAULT_OPTIONS = {
  speed: {
    min: 0.5,
    max: 3,
  },
  stars: 1024,
  density: 256,
};

export class Stars {
  constructor(element) {
    this.element = element;
    this.setSizeAndCenter();
    this.setContext();

    this.starLengthRatio = 1 / this.center.z;
    this.stars = new Array(DEFAULT_OPTIONS.stars);
    this.holding = false;

    this.init();
  }

  setSizeAndCenter = () => {
    this.size = {
      width: window.innerWidth,
      height: window.innerHeight - variables.fullHeader,
    };

    this.center = {
      x: Math.round(this.size.width / 2),
      y: Math.round(this.size.height / 2),
      z: (this.size.width + this.size.height) / 2,
    };
  };

  setContext = () => {
    this.context = this.element.getContext("2d");
    this.context.canvas.width = this.size.width;
    this.context.canvas.height = this.size.height;
    this.context.fillStyle = this.gradient();
    this.context.strokeStyle = "white";
  };

  init = () => {
    for (let i = 0; i < DEFAULT_OPTIONS.stars; i++) {
      this.stars[i] = new Array(6);
      this.stars[i][STAR.CUR_X] = Math.random() * this.size.width * 2 - this.center.x * 2;
      this.stars[i][STAR.CUR_Y] = Math.random() * this.size.height * 2 - this.center.y * 2;
      this.stars[i][STAR.CUR_Z] = Math.round(Math.random() * this.center.z);
      this.stars[i][STAR.SPEED] = this.randomBetween(DEFAULT_OPTIONS.speed.min, DEFAULT_OPTIONS.speed.max);
      this.stars[i][STAR.ORI_X] = 0;
      this.stars[i][STAR.ORI_Y] = 0;
    }

    this.update();
    window.addEventListener("resize", this.resize);
    window.addEventListener("orientationchange", this.resize);
  };

  remove = () => {
    window.cancelAnimationFrame(this.update);
    window.removeEventListener("resize", this.resize);
    window.removeEventListener("orientationchange", this.resize);
  };

  update = () => {
    this.animate();
    window.requestAnimationFrame(this.update);
  };

  animate = () => {
    this.context.fillRect(0, 0, this.size.width, this.size.height);

    this.stars.forEach(star => {
      let update = true;
      const starX = star[STAR.ORI_X];
      const starY = star[STAR.ORI_Y];

      // X coords
      if (star[STAR.CUR_X] > this.center.x << 1) {
        star[STAR.CUR_X] -= this.size.width << 1;
        update = false;
      }
      if (star[STAR.CUR_X] < -this.center.x << 1) {
        star[STAR.CUR_X] += this.size.width << 1;
        update = false;
      }

      // Y coords
      if (star[STAR.CUR_Y] > this.center.y << 1) {
        star[STAR.CUR_Y] -= this.size.height << 1;
        update = false;
      }
      if (star[STAR.CUR_Y] < -this.center.y << 1) {
        star[STAR.CUR_Y] += this.size.height << 1;
        update = false;
      }

      // Z coords
      star[STAR.CUR_Z] -= star[STAR.SPEED];
      if (star[STAR.CUR_Z] > this.center.z) {
        star[STAR.CUR_Z] -= this.center.z;
        update = false;
      }
      if (star[STAR.CUR_Z] < 0) {
        star[STAR.CUR_Z] += this.center.z;
        update = false;
      }

      star[STAR.ORI_X] = this.center.x + (star[STAR.CUR_X] / star[STAR.CUR_Z]) * DEFAULT_OPTIONS.density;
      star[STAR.ORI_Y] = this.center.y + (star[STAR.CUR_Y] / star[STAR.CUR_Z]) * DEFAULT_OPTIONS.density;

      if (starX > 0 && starX < this.size.width && starY > 0 && starY < this.size.height && update) {
        this.context.lineWidth = (1 - this.starLengthRatio * star[STAR.CUR_Z]) * 2;
        this.context.beginPath();
        this.context.moveTo(starX, starY);
        this.context.lineTo(star[STAR.ORI_X], star[STAR.ORI_Y]);
        this.context.stroke();
        this.context.closePath();
      }
    });
  };

  resize = () => {
    const initial = {
      width: this.context.canvas.width,
      height: this.context.canvas.height,
    };

    this.setSizeAndCenter();
    this.setContext();

    const ratio = {
      x: this.size.width / initial.width,
      y: this.size.height / initial.height,
    };

    this.stars.forEach(star => {
      star[STAR.CUR_X] = star[STAR.CUR_X] * ratio.x;
      star[STAR.CUR_Y] = star[STAR.CUR_Y] * ratio.y;

      star[STAR.ORI_X] = this.center.x + (star[STAR.CUR_X] / star[STAR.CUR_Z]) * DEFAULT_OPTIONS.density;
      star[STAR.ORI_Y] = this.center.y + (star[STAR.CUR_Y] / star[STAR.CUR_Z]) * DEFAULT_OPTIONS.density;
    });
  };

  gradient = () => {
    const x = this.center.x;
    const y = this.center.y;
    const r = Math.sqrt(x * x + y * y);
    const gradient = this.context.createRadialGradient(x, y, 0, x, y, r);
    gradient.addColorStop(0, variables.colors.space.in);
    gradient.addColorStop(0.7, variables.colors.space.out);
    return gradient;
  };

  randomBetween = (min, max) => Math.random() * (max - min) + min;
}
